import { handleErrorWithSentry } from "@sentry/sveltekit";
import * as Sentry from '@sentry/sveltekit';

import { PUBLIC_RELEASE } from '$env/static/public';
import { PUBLIC_ENVIRONMENT } from '$env/static/public';
import { PUBLIC_SENTRY_DSN } from '$env/static/public';

if (!!PUBLIC_SENTRY_DSN) {
  Sentry.init({
    dsn: PUBLIC_SENTRY_DSN,
    tracesSampleRate: 1.0,
    release: PUBLIC_RELEASE,
    environment: PUBLIC_ENVIRONMENT,
  });
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
